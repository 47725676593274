<template lang="">
  <b-container fluid style="background-color: #fff">
    <b-row>
      <b-col md="8" lg="8" class="" v-if="operatingsystem == 'web'">
        <iq-card class="p-4 banner-text-left-card">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img :src="orgData.org_cover_img" style="width:100%" />
            <!-- Event Description -->
            <div class="col-12 mt-4">
              <span id="contactUsV1EventDescId" v-html="orgData.org_desc"></span>
            </div><!-- Event Description -->
          </template>
        </iq-card>
      </b-col>

      <b-col md="8" lg="8" class="" v-if="operatingsystem != 'web'">
        <iq-card class="ban_card">
          <template v-slot:headerTitle>
            <img :src="orgData.org_cover_img" style="width:100%" />
          </template>
        </iq-card>
      </b-col>

      <b-col lg="4" style='background-color:#fff'>
        <div class="p-3">
          <h3 class="">
            <span class="primary-color">{{orgData.org_name}}</span>
          </h3>
          <h3 class="mt-4">
            Sign In
          </h3>
          <p>Don't have an account? <span class="primary-color">
            Sign Up
          </span></p>
          <div>
            <div id="buttonDiv" style="width: 100%;">
            </div>
          </div>
          <ValidationObserver ref="loginForm">
            <form class="mt-4" @submit.prevent="login">
              <div class="form-group">
                <label for="validationTooltipEmail">Email address</label>
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                  <input
                    v-model="userEmail"
                    type="email"
                    class="form-control mb-0"
                    id="validationTooltipEmail"
                    placeholder="Email"
                    name="Email">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="d-inline-block w-100">
                <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                  <input type="checkbox" class="custom-control-input" id="customCheck1">
                  <label class="custom-control-label" for="customCheck1">Remember Me</label>
                </div>
                <button type="" class="btn btn-primary float-right">
                  Sign In
                </button>
              </div>

              <div class="sign-info">
                <span class="dark-color d-inline-block line-height-2">Are you a school, university, edtech or any other organisation ? <span class="primary-color">Contact Us</span></span>
                <span class="dark-color d-inline-block mt-4" style="line-height: 1.2;">
                  <a href="mailto:support@gide.ai">Need Help !</a><br>
                  <small>[Support timings between 11am to 5pm]</small><br>
                </span>
                <div>
                  <ul class="iq-social-media mt-2">
                    <li>
                      <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
                    </li>
                    <li>
                      <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </ValidationObserver>

          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>
    </b-row>
  </b-container>
</template>
<script>
import { socialvue } from "../../../config/pluginInit"
import { Organisations } from "../../../FackApi/api/organisation"

export default {
  name: "OrgSaasLoginPagePreview",
  data () {
    return {
      apple_signin_usePopup: false,
      user_password: "",
      userEmail: "",
      showPasswordField: false,
      operatingsystem: window.__DEVICE_OS__,
      auth_loader: false,
      cvOrgId: null,
      cvOrgType: null,
      orgData: {},
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Login Page Preview"
    }
  },
  mounted () {
    socialvue.index()
    if (this.$route.params.org_id) {
      this.cvOrgId = this.$route.params.org_id
    }

    if (this.$route.params.org_type) {
      this.cvOrgType = this.$route.params.org_type
    }
    this.getOrgDetails()
  },
  methods: {
    /**
     * getOrgDetails
     */
    async getOrgDetails () {
      try {
        const orgDetails = await Organisations.organisationView(this, this.cvOrgId)
        if (!orgDetails.resp_status) {
          return
        }
        this.orgData = orgDetails.resp_data.data
        console.log("Org Data ", this.orgData)
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    }
  }
}
</script>
<style lang="">

</style>
